// eslint-disable-next-line
import { useEffect } from "react";
import { handleAuthClick } from "../../../services/AODocs/authentication";

import {
  AUTHORIZE_EVENT,
  AODOCS_AUTHORIZE_EVENT_DONE,
} from "../../../services/iframeCommunication/actions";
import {
  authorizeEvent,
  emitToParent,
} from "../../../services/iframeCommunication";

const AuthorizationListener = ({ stateChange }) => {
  useEffect(() => {
    function listener(event) {
      authorizeEvent(event).then((allowed) => {
        if (!allowed) return;
        const { data } = event;
        if (data[AUTHORIZE_EVENT]) {
          authorize((result) => {
            emitToParent({
              [AODOCS_AUTHORIZE_EVENT_DONE]: {
                ...result,
              },
            });
            if (typeof stateChange === "function") {
              stateChange(result);
            }
          });
        }
      });
    }
    window.addEventListener("message", (event) => listener(event), false);
    return window.removeEventListener("message", listener);
  }, [stateChange]);

  const authorize = (callback) => {
    handleAuthClick(callback);
  };

  return null;
};
export default AuthorizationListener;
