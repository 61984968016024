import { defineMessages } from "react-intl.macro";

export default defineMessages({
  allLoaded: {
    id: "info.title.all.loaded",
    defaultMessage: "You've reached the end.",
  },
  noResultsTitle: {
    id: "info.title.no.results",
    defaultMessage: "No results found.",
  },
  serverError: {
    id: "info.title.server.error",
    defaultMessage: "We had some trouble connecting to the server.",
  },
  reloadPage: {
    id: "info.button.reload.page",
    defaultMessage: "Reload page",
  },
  serverErrorMsg: {
    id: "info.message.server.error",
    defaultMessage:
      "An error occurred when we tried to process your request. Please try again later.",
  },
  readMore: {
    id: "info.button.readMore",
    defaultMessage: "Read more",
  },
  authorizeIntegration: {
    id: "info.button.authorizeIntegration",
    defaultMessage: "Authorize Integration",
  },
});
