/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { TinyText } from "@happeouikit/typography";
import { Tooltip } from "@happeouikit/tooltip";
import { Spacer, margin200 } from "@happeouikit/layout";
import { Loader } from "@happeouikit/loaders";
import { IconFileAodocs } from "@happeouikit/icons";
import { Line300 } from "@happeouikit/theme";
import { makeCancelable, openInNewTab } from "@universe/frontend-utils";
import { trackEvent, AODOCS_OPEN_DOCUMENT } from "../../AppAnalytics";
import {
  getDocumentByDriveId,
  distributeMetadataToSections,
  isAuthorizationRequired,
} from "../../../services/AODocs/api";

import { ErrorPage } from "../../Info";
import { HeaderRow } from "./MetadataItems/SharedStyles";
import {
  DocumentDates,
  MetadataSections,
  DocumentPrimaryData,
  DocumentWorkflowState,
  DocumentDescription,
} from "./MetadataItems";
import messages from "../messages";
import { emitToParent } from "../../../services/iframeCommunication";
import { RESIZE_EVENT } from "../../../services/iframeCommunication/actions";
import { AODOCS_AUTHORIZATION_STATES } from "../../../services/AODocs/constants";

const AODocsMetadata = ({
  documentId,
  showExtendedProperties,
  showLoader,
  showError,
  showHeader,
  setAODocsIntegrationState,
}) => {
  const [document, setDocument] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const containerRef = useRef(null);
  const intl = useIntl();

  useEffect(() => {
    if (!documentId) return;

    setLoading(showLoader);
    const cancelableGetDocument = makeCancelable(
      getDocumentByDriveId(documentId),
    );

    cancelableGetDocument.promise
      .then(({ data }) => {
        const mappedDocument = distributeMetadataToSections(data);
        setDocument(mappedDocument);
        setLoading(false);
      })
      .catch((error) => {
        if (error.isCanceled) {
          return;
        }
        if (isAuthorizationRequired(error.response)) {
          setAODocsIntegrationState(AODOCS_AUTHORIZATION_STATES.unauthorized);
        }

        setLoading(false);
        setError(showError);
      });

    // eslint-disable-next-line consistent-return
    return () => {
      cancelableGetDocument.cancel();
    };
  }, [documentId, showLoader, showError, setAODocsIntegrationState]);

  useEffect(() => {
    const height = Math.max(containerRef.current?.scrollHeight + 30, 400);
    emitToParent({
      [RESIZE_EVENT]: {
        documentId,
        height: `${height}px`,
      },
    });
  }, [document, documentId]);

  const openDocument = (e) => {
    e.stopPropagation();
    trackEvent(AODOCS_OPEN_DOCUMENT);
    openInNewTab(document.documentUrl);
  };

  return (
    <Container ref={containerRef}>
      {error && (
        <ErrorPage
          hideAction
          containerStyles={{
            paddingTop: 0,
            paddingBottom: "32px",
            textAlign: "center",
          }}
          imageStyles={{ width: "240px" }}
        />
      )}
      {loading && (
        <div data-testid="aodocs-metadata-loader">
          <Loader />
        </div>
      )}
      {!loading && !error && document && (
        <>
          {showHeader && (
            <HeaderRow data-testid="aodocs-metadata-header">
              <AODocsLogo>
                <IconFileAodocs width="18px" height="18px" />
                <TinyText bold uppercase>
                  {intl.formatMessage(messages.metadataButtonTooltip)}
                </TinyText>
              </AODocsLogo>
              <Tooltip
                id={`${document.id}_open_details`}
                content={intl.formatMessage(messages.metadataButtonShowDetails)}
                place="left"
              />
            </HeaderRow>
          )}
          <DocumentPrimaryData
            data-testid="aodocs-metadata-primary"
            document={document}
            openDocument={openDocument}
          />
          {document.state_i18n && <DocumentWorkflowState document={document} />}
          {showExtendedProperties && (
            <>
              <DocumentDates document={document} />
              <>
                <Spacer />
                <Line300 />
                <Spacer />
              </>
              <MetadataSections sections={document.mappedSections} />
              {document.richText && (
                <DocumentDescription richText={document.richText} />
              )}
            </>
          )}
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  cursor: auto;
`;

const AODocsLogo = styled.div`
  display: flex;
  align-items: center;

  & > p {
    margin: 0px ${margin200};
  }
`;

AODocsMetadata.defaultProps = {
  driveFileId: null,
  aoDocsDocument: null,
  showExtendedProperties: true,
  showLoader: true,
  showError: true,
  showHeader: true,
};

const AODocsMetadataWrapper = ({ match }) => {
  const { params } = match;
  const { type, documentId } = params;
  const showLoader = type !== "card";
  const showError = type !== "card";
  const showHeader = type === "card";
  const showExtendedProperties = type !== "card";

  return (
    <AODocsMetadata
      documentId={documentId}
      showLoader={showLoader}
      showError={showError}
      showHeader={showHeader}
      showExtendedProperties={showExtendedProperties}
    />
  );
};

export default AODocsMetadataWrapper;
