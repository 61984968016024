/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";
import {
  ButtonPrimary,
  ButtonSecondary,
  IconButton,
} from "@happeouikit/buttons";
import { Input } from "@happeouikit/form-elements";
import { Spacer } from "@happeouikit/layout";
import { lighten80, lighten90, active } from "@happeouikit/colors";
import {
  makeCancelable,
  handleDoubleClick,
  isMobileOrTablet,
} from "@universe/frontend-utils";
import { Loader } from "@happeouikit/loaders";
import { ListHeader, LiCol, LiStriped } from "@happeouikit/list";
import { BodyUI } from "@happeouikit/typography";
import {
  IconArrowForward,
  IconArrowBack,
  IconSearch,
} from "@happeouikit/icons";
import messages from "../messages";
import {
  isAuthorizationRequired,
  listPlainLibraries,
  listViews,
} from "../../../services/AODocs/api";
import { trackEvent, AODOCS_VIEW_PICKER_OPEN } from "../../AppAnalytics";
import { ErrorPage, NoResults } from "../../Info";
import { emitToParent } from "../../../services/iframeCommunication";
import { AODOCS_PICKER_EVENT } from "../../../services/iframeCommunication/actions";
import { AODOCS_AUTHORIZATION_STATES } from "../../../services/AODocs/constants";

const AODocsLibrariesList = ({ libraries = [], onSelect }) => {
  const intl = useIntl();

  if (libraries.length === 0) {
    return (
      <NoResults
        containerStyles={{ paddingTop: 0, paddingBottom: "32px" }}
        imageStyles={{ width: "200px" }}
      />
    );
  }

  const libraryHeaders = [
    { name: intl.formatMessage(messages.headerLibrary), width: "80%" },
    { name: "", width: "17%" },
  ];

  const isMobile = isMobileOrTablet();
  return (
    <>
      <ul>
        <ListHeader headers={libraryHeaders} sortDir="desc" />
      </ul>
      <StyledUL data-testid="aodocs-library-list">
        {libraries.map((library) => (
          <StyledLiStriped
            data-testid="aodocs-library-list-item"
            key={library.libraryId}
            onClick={() =>
              !isMobile && handleDoubleClick(() => onSelect(library.libraryId))
            }
            touchEnd={() => {
              handleDoubleClick(() => onSelect(library.libraryId));
            }}
          >
            <LiCol width="80%">
              <BodyUI>{library.name}</BodyUI>
            </LiCol>
            <LiCol width="17%" style={{ justifyContent: "flex-end" }}>
              <IconButton
                aria-label={intl.formatMessage(messages.openLibraryButton)}
                icon={IconArrowForward}
                onClick={() => onSelect(library.libraryId)}
                data-testid="aodocs-library-list-item-open"
              />
            </LiCol>
          </StyledLiStriped>
        ))}
      </StyledUL>
    </>
  );
};

const AODocsViewsList = ({ views = [], back, selectedView, onSelect }) => {
  const intl = useIntl();
  const viewHeaders = [
    { name: intl.formatMessage(messages.headerView), width: "100%" },
  ];
  return (
    <>
      <div>
        <ButtonSecondary
          icon={IconArrowBack}
          text={intl.formatMessage(messages.backButton)}
          onClick={back}
        />
      </div>
      <ul>
        <ListHeader headers={viewHeaders} sortDir="desc" sortFn={() => ""} />
      </ul>
      <StyledUL data-testid="aodocs-view-list">
        {views.map((view) => (
          <StyledLiStriped
            data-testid="aodocs-view-list-item"
            key={view.id}
            onClick={() => onSelect(view.id)}
            isSelected={selectedView === view.id}
          >
            <LiCol width="100%">
              <BodyUI>{view.name}</BodyUI>
            </LiCol>
          </StyledLiStriped>
        ))}
        {views.length === 0 && (
          <NoResults
            containerStyles={{ paddingTop: 0, paddingBottom: "32px" }}
            imageStyles={{ width: "200px" }}
          />
        )}
      </StyledUL>
    </>
  );
};

const AODocsViewPickerModal = ({ callback, setAODocsIntegrationState }) => {
  const intl = useIntl();
  const [selectedLibrary, setSelectedLibrary] = useState(false);
  const [selectedView, setSelectedView] = useState(false);
  const [libraries, setLibraries] = useState(false);
  const [filteredLibraries, setFilteredLibraries] = useState(null);
  const [views, setViews] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [filterValue, setFilterValue] = useState("");

  useEffect(() => {
    trackEvent(AODOCS_VIEW_PICKER_OPEN);

    setLoading(true);
    const cancelableListLibraries = makeCancelable(listPlainLibraries());

    cancelableListLibraries.promise
      .then(({ data }) => {
        setLibraries(data.libraries);
        setFilteredLibraries(data.libraries);
        setLoading(false);
      })
      .catch((error) => {
        if (error.isCanceled) {
          return;
        }
        if (isAuthorizationRequired(error.response)) {
          setAODocsIntegrationState(AODOCS_AUTHORIZATION_STATES.unauthorized);
        }
        setLoading(false);
        setError(error);
      });

    return () => {
      cancelableListLibraries.cancel();
    };
  }, [setAODocsIntegrationState]);

  useEffect(() => {
    if (!selectedLibrary) return;
    setLoading(true);
    const cancelableListViews = makeCancelable(listViews(selectedLibrary));

    cancelableListViews.promise
      .then(({ data }) => {
        setViews(data.items);
        setLoading(false);
      })
      .catch((error) => {
        if (error.isCanceled) {
          return;
        }
        setLoading(false);
        setError(error);
      });

    return () => {
      cancelableListViews.cancel();
    };
  }, [selectedLibrary]);

  const cancel = () => {
    const data = { cancel: true };
    emitToParent({
      [AODOCS_PICKER_EVENT]: data,
    });
    if (typeof callback === "function") {
      callback(data);
    }
  };

  const select = () => {
    const data = {
      selectedView,
      selectedLibrary,
    };
    emitToParent({
      [AODOCS_PICKER_EVENT]: data,
    });
    if (typeof callback === "function") {
      callback(data);
    }
  };

  const filterLibraries = (filter) => {
    if (filterValue.length === 0) {
      setFilteredLibraries(libraries);
      return;
    }
    setFilteredLibraries(
      libraries.filter((library) =>
        library.name.toLowerCase().includes(filter),
      ),
    );
  };

  return (
    <Container>
      <FlexContainer>
        {loading && <Loader />}
        {!loading && error && (
          <ErrorPage
            hideAction
            containerStyles={{
              paddingTop: 0,
              paddingBottom: "32px",
              textAlign: "center",
            }}
            imageStyles={{ width: "240px" }}
          />
        )}
        {!loading && !error && (
          <>
            {!selectedLibrary && libraries && (
              <div style={{ minHeight: "450px" }}>
                <Input
                  type="text"
                  placeholder={intl.formatMessage(
                    messages.modalFilterPlaceholder,
                  )}
                  value={filterValue}
                  onChange={(e) => {
                    setFilterValue(e.target.value);
                    filterLibraries(e.target.value);
                  }}
                  icon={IconSearch}
                />
                <AODocsLibrariesList
                  libraries={filteredLibraries}
                  onSelect={setSelectedLibrary}
                />
              </div>
            )}
            {selectedLibrary && views && (
              <AODocsViewsList
                views={views}
                back={() => {
                  setSelectedLibrary(null);
                  setSelectedView(null);
                }}
                selectedView={selectedView}
                onSelect={setSelectedView}
              />
            )}
          </>
        )}
      </FlexContainer>
      <Spacer />
      <ModalFooter>
        <ButtonSecondary
          onClick={cancel}
          text={intl.formatMessage(messages.cancelButton)}
          data-testid="aodocs-modal-cancel"
        />
        <Spacer />
        <ButtonPrimary
          text={intl.formatMessage(messages.selectButton)}
          onClick={select}
          disabled={!selectedView}
          data-testid="aodocs-modal-select"
        />
      </ModalFooter>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
  overflow: auto;
`;
const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const StyledLiStriped = styled(LiStriped)`
  cursor: pointer;
  transition: background 0.05s ease-out;
  padding: 4px 16px;
  min-height: 52px;
  background: ${({ isSelected }) =>
    isSelected && `${lighten80(active)} !important`};

  :hover {
    background: ${lighten90(active)} !important;
  }

  :active {
    background: ${lighten80(active)} !important;
  }
`;
const StyledUL = styled.ul`
  max-height: 400px;
  overflow: auto;
`;

export default AODocsViewPickerModal;
