import React from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { TextDelta, BodyUI } from "@happeouikit/typography";
import { ButtonPrimary } from "@happeouikit/buttons";
import { gray04, gray07 } from "@happeouikit/colors";
import messages from "./messages";

const Unauthorized = ({ icon, title, description, primaryAction }) => {
  const intl = useIntl();

  return (
    <Container>
      <TypeIcon>{icon}</TypeIcon>
      <StyledTextDelta>{title}</StyledTextDelta>
      <StyledBodyUI>
        {`${description} `}
        <a
          href="https://www.happeo.com/knowledge/user-settings#integrations"
          target="_blank"
          rel="noopener noreferrer"
        >
          {intl.formatMessage(messages.readMore)}
        </a>
      </StyledBodyUI>
      <ButtonPrimary
        text={intl.formatMessage(messages.authorizeIntegration)}
        size="small"
        onClick={primaryAction}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 550px;
  margin: auto;
`;

const StyledTextDelta = styled(TextDelta)`
  margin: 16px 0 8px 0;
  text-align: center;
`;

const StyledBodyUI = styled(BodyUI)`
  margin-bottom: 34px;
  text-align: center;
  color: ${gray04};
`;

const TypeIcon = styled.div`
  display: inherit;

  svg {
    fill: ${gray07};
    width: 72px;
    height: 72px;
  }
`;

export default Unauthorized;
