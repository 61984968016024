const AODOCS_DEV_CLIENT_ID =
  "779721836595-lbvkmg4ml681afr1mjnv44uklfv1398d.apps.googleusercontent.com";
const AODOCS_PROD_CLIENT_ID =
  "3857084548-qlvsdtdo8o1son4j0td9ck2qdhvf1240.apps.googleusercontent.com";

const isProd = window.location.host.includes("happeo.com");

export const AODOCS_CLIENT_ID = isProd
  ? AODOCS_PROD_CLIENT_ID
  : AODOCS_DEV_CLIENT_ID;
export const AFTER_AUTH_TIMEOUT = 1000;
export const AODOCS_DISCOVERY_DOCS = [];
export const AODOCS_SCOPES = "https://www.googleapis.com/auth/userinfo.email";
export const AODOCS_BASE_URL = "https://ao-docs.appspot.com/_ah/api";
export const AODOCS_METADATA_FIELD_TYPES = {
  STRING: "STRING",
  TEXT: "TEXT",
  BOOLEAN: "BOOLEAN",
  INTEGER: "INTEGER",
  DECIMAL: "DECIMAL",
  PERSON: "PERSON",
  GEOPOINT: "GEOPOINT",
  DATE: "DATE",
  DATETIME: "DATETIME",
  TIME: "TIME",
  URL: "URL",
};
export const AODOCS_AUTHORIZATION_STATES = {
  authorized: "authorized",
  unauthorized: "unauthorized",
};
