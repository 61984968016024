import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { IconLock } from "@happeouikit/icons";

import { handleAuthClick } from "../../../services/AODocs/authentication";
import { AODOCS_AUTHORIZATION_STATES } from "../../../services/AODocs/constants";

import messages from "../messages";
import { AuthorizationListener } from ".";
import { emitToParent } from "../../../services/iframeCommunication";
import { AODOCS_AUTHORIZATION_STATUS } from "../../../services/iframeCommunication/actions";
import { Unauthorized } from "../../Info";

const AuthorizationWrapper = ({ state, type, children, stateChange }) => {
  const intl = useIntl();

  useEffect(() => {
    if (type === "none") {
      emitToParent({
        [AODOCS_AUTHORIZATION_STATUS]: state,
      });
    }
  }, [state, type]);

  if (state === AODOCS_AUTHORIZATION_STATES.unauthorized) {
    return type === "none" ? (
      <AuthorizationListener stateChange={stateChange} />
    ) : (
      <MessageWrapper>
        <Unauthorized
          icon={<IconLock />}
          title={intl.formatMessage(messages.unauthorizedTitle)}
          description={intl.formatMessage(messages.unauthorizedSubtitle)}
          primaryAction={() => handleAuthClick(stateChange)}
        />
      </MessageWrapper>
    );
  }

  return children;
};

const MessageWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export default AuthorizationWrapper;
