/* eslint-disable camelcase */
import axios from "axios";
import { AODOCS_BASE_URL, AODOCS_METADATA_FIELD_TYPES } from "./constants";

let AODocsApi;
let expiresAt;

export function isApiInitialised() {
  const now = Date.now();
  if (expiresAt && expiresAt <= now) {
    AODocsApi = null;
  }

  return !!AODocsApi;
}

export function createAPIInstance(authorization, expiresIn) {
  expiresAt = Date.now() + expiresIn * 1000;
  AODocsApi = axios.create({
    baseURL: AODOCS_BASE_URL,
    timeout: 60000,
    headers: {
      authorization,
    },
  });
}

export function listPlainLibraries() {
  if (!isApiInitialised()) return Promise.reject({ notInitialized: true });
  return AODocsApi?.put("/library/v1/plain");
}

export function getLibrary(id) {
  if (!isApiInitialised()) return Promise.reject({ notInitialized: true });
  return AODocsApi?.get(`/library/v1/${id}`);
}

export function listViews(libraryId) {
  if (!isApiInitialised()) return Promise.reject({ notInitialized: true });
  return AODocsApi?.get(`/view/v1/libraries/${libraryId}`);
}

export function getDocumentByDriveId(id) {
  if (!isApiInitialised()) return Promise.reject({ notInitialized: true });
  return AODocsApi?.get(`/document/v1/drive/${id}`);
}

export function listWorkflowTasks() {
  if (!isApiInitialised()) return Promise.reject({ notInitialized: true });
  return AODocsApi?.get("/user/v1/workflowTasks");
}

export function getDocumentWorkflowTransitions(id) {
  if (!isApiInitialised()) return Promise.reject({ notInitialized: true });
  return AODocsApi?.get(`/workflow/v1/documents/${id}/transitions`);
}

export function getEmbedUrlForView(libraryId, viewId, user, options = {}) {
  const baseUrl = "https://aodocs.altirnao.com/?embedded=1";
  const domain = `aodocs-domain=${user.organisation.primaryDomain}`;
  const search = `hideSearch=${options.hideSearch ? 1 : 0}`;
  const pageSize = `pageSize=${options.pageSize || 20}`;
  const locale = `locale=${options.locale || "en"}`;
  const uri = `#Menu_listDoc/LibraryId_${libraryId}/ViewId_${viewId}`;

  return `${baseUrl}&${locale}&${domain}&${search}&${pageSize}${uri}`;
}

export function getLibraryUrl({ libraryId, domainName }) {
  return `https://aodocs.altirnao.com/?aodocs-domain=${domainName}#Menu_libraryHome/LibraryId_${libraryId}`;
}

export function getDocumentUrl({ domainName, libraryId, id }) {
  return `https://aodocs.altirnao.com/?aodocs-domain=${domainName}#Menu_viewDoc/LibraryId_${libraryId}/DocumentId_${id}`;
}

export const distributeMetadataToSections = (document) => {
  if (!document) return null;
  if (!document.sections) return document;
  const validSections = document.sections?.filter((section) => section.fields);
  const mappedSections = validSections.map((section) => {
    const fieldArray = section.fields?.map((field) => field.id);
    const fields = document.fields?.filter(
      (field) => fieldArray.includes(field.fieldId) && !!field.values,
    );

    const mappedFields = fields?.map((field) => ({
      ...field,
      values: field.values?.map((value) => {
        if (field.type !== AODOCS_METADATA_FIELD_TYPES.PERSON) {
          return value;
        }
        return document.userMapping[value]
          ? {
              ...document.userMapping[value],
              thumbnailPhotoUrl:
                document.userMapping[value].thumbnailPictureUrl,
            }
          : { email: value };
      }),
    }));
    const name = section.name === "DEFAULT" ? "Properties" : section.name;
    return {
      name,
      fields: mappedFields,
    };
  });

  const author = document.userMapping[document.initialAuthor]
    ? {
        ...document.userMapping[document.initialAuthor],
        thumbnailPhotoUrl:
          document.userMapping[document.initialAuthor].thumbnailPictureUrl,
      }
    : { email: document.initialAuthor };

  const updater = document.userMapping[document.updateAuthor]
    ? {
        ...document.userMapping[document.updateAuthor],
        thumbnailPhotoUrl:
          document.userMapping[document.updateAuthor].thumbnailPictureUrl,
      }
    : { email: document.updateAuthor };

  return {
    ...document,
    libraryUrl: getLibraryUrl(document),
    documentUrl: getDocumentUrl(document),
    author,
    updater,
    mappedSections: mappedSections.filter(
      (section) => section.fields?.length > 0,
    ),
  };
};

export function isAuthorizationRequired(error = {}) {
  return error.notInitialized || [401, 403].includes(error.status);
}
