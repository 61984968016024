/* eslint-disable react/no-array-index-key */
import React, { useState } from "react";
import moment from "moment";
import { useIntl } from "react-intl";
import { Avatar } from "@happeouikit/avatar";
import { IconButton } from "@happeouikit/buttons";
import { ContentRenderer } from "@happeouikit/content-renderer";
import { Switch, LinkExternal } from "@happeouikit/form-elements";
import { BodyUI, TextZeta } from "@happeouikit/typography";
import { IconExpandLess, IconExpand } from "@happeouikit/icons";
import { gray09 } from "@happeouikit/colors";

import {
  PersonFieldWrap,
  SectionHeader,
  MetadataHeader,
  Field,
} from "./SharedStyles";
import messages from "../../messages";
import { AODOCS_METADATA_FIELD_TYPES } from "../../../../services/AODocs/constants";

const Geopoint = ({ value }) => {
  const intl = useIntl();
  try {
    const json = JSON.parse(value);
    const url = `https://www.google.com/maps/search/?api=1&query=${json.latitude},${json.longitude}`;
    return (
      <BodyUI>
        <LinkExternal href={url}>
          {intl.formatMessage(messages.metadataSectionItemOpenMap)}
        </LinkExternal>
      </BodyUI>
    );
  } catch (error) {
    return <BodyUI>{value}</BodyUI>;
  }
};

const PersonField = ({ user }) => (
  <PersonFieldWrap>
    <Avatar user={user} size="s" />
    <div style={{ marginLeft: "8px" }}>
      <BodyUI bold>{user.name}</BodyUI>
      <BodyUI>{user.email}</BodyUI>
    </div>
  </PersonFieldWrap>
);

const FieldValue = ({ fieldId, field, value }) => {
  switch (field.type) {
    case AODOCS_METADATA_FIELD_TYPES.PERSON:
      return <PersonField user={value} />;
    case AODOCS_METADATA_FIELD_TYPES.BOOLEAN:
      return (
        <Switch
          checked={value === "true"}
          readOnly
          aria-labelledby={`header_${fieldId}`}
        />
      );
    case AODOCS_METADATA_FIELD_TYPES.URL:
      return <ContentRenderer type="markdown" content={value} />;
    case AODOCS_METADATA_FIELD_TYPES.DATE:
      return <BodyUI>{moment(value, "x").format("L")}</BodyUI>;
    case AODOCS_METADATA_FIELD_TYPES.DATETIME:
      return <BodyUI>{moment(value, "x").format("LLL")}</BodyUI>;
    case AODOCS_METADATA_FIELD_TYPES.TIME:
      return <BodyUI>{moment(value, "x").format("LT")}</BodyUI>;
    case AODOCS_METADATA_FIELD_TYPES.GEOPOINT:
      return <Geopoint value={value} />;
    default:
      return <BodyUI>{value}</BodyUI>;
  }
};

const MetadataSection = ({ section = {} }) => {
  const [isOpen, setIsOpen] = useState(false);
  const intl = useIntl();
  return (
    <li>
      <SectionHeader>
        <TextZeta style={{ marginBottom: "8px" }}>{section.name}</TextZeta>
        <IconButton
          aria-label={intl.formatMessage(messages.metadataSectionExpand)}
          icon={isOpen ? IconExpandLess : IconExpand}
          onClick={() => setIsOpen(!isOpen)}
        />
      </SectionHeader>
      {isOpen && (
        <ul
          style={{
            padding: "16px",
            borderRadius: "6px",
            marginBottom: "16px",
            backgroundColor: gray09,
          }}
        >
          {section.fields &&
            section.fields.map((field) => (
              <Field key={field.fieldId}>
                <MetadataHeader uppercase bold id={`header_${field.fieldId}`}>
                  {field.fieldName_i18n}
                </MetadataHeader>
                <ul>
                  {field.values &&
                    field.values.map((value) => (
                      <li key={field.fieldId}>
                        <FieldValue
                          field={field}
                          value={value}
                          fieldId={field.fieldId}
                        />
                      </li>
                    ))}
                </ul>
              </Field>
            ))}
        </ul>
      )}
    </li>
  );
};

const MetadataSections = ({ sections = [] }) => (
  <ul>
    {sections.map((section, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <MetadataSection key={index} section={section} />
    ))}
  </ul>
);

export default MetadataSections;
