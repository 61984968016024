import React from "react";
import { useIntl } from "react-intl";
import { BodyUI } from "@happeouikit/typography";
import { IconButton } from "@happeouikit/buttons";
import { IconExternalLink } from "@happeouikit/icons";
import { Tooltip } from "@happeouikit/tooltip";
import {
  MetadataItem,
  MetadataTable,
  MetadataTableColumn,
  MetadataHeader,
  MetadataAction,
  MetadataContent,
} from "./SharedStyles";
import messages from "../../messages";

const DocumentPrimaryData = ({ document, openDocument }) => {
  const intl = useIntl();

  return (
    <MetadataItem>
      <MetadataTable>
        <MetadataTableColumn>
          <MetadataHeader uppercase bold>
            {intl.formatMessage(messages.metadataItemHeaderDocument)}
          </MetadataHeader>
          <MetadataContent>
            <BodyUI>{document.title}</BodyUI>
            <MetadataAction>
              <IconButton
                data-tip
                data-for={`${document.libraryId}_open`}
                aria-label={intl.formatMessage(
                  messages.metadataButtonOpenDocument,
                )}
                icon={IconExternalLink}
                onClick={openDocument}
              />
              <Tooltip
                id={`${document.libraryId}_open`}
                content={intl.formatMessage(
                  messages.metadataButtonOpenDocument,
                )}
              />
            </MetadataAction>
          </MetadataContent>
        </MetadataTableColumn>

        <MetadataTableColumn>
          <MetadataHeader uppercase bold>
            {intl.formatMessage(messages.metadataItemHeaderLibrary)}
          </MetadataHeader>
          <MetadataContent>
            <BodyUI>{document.libraryName}</BodyUI>
          </MetadataContent>
        </MetadataTableColumn>
      </MetadataTable>
    </MetadataItem>
  );
};

export default DocumentPrimaryData;
