/* eslint-disable camelcase */
/* global gapi */

import {
  AODOCS_AUTHORIZATION_STATES,
  AODOCS_CLIENT_ID,
  AODOCS_SCOPES,
} from "./constants";
import { createAPIInstance } from "./api";

function handleSuccess(user, callback) {
  let state = AODOCS_AUTHORIZATION_STATES.unauthorized;
  const authResponse = user.getAuthResponse();
  const {
    access_token: accessToken,
    token_type: tokenType,
    expires_in: expiresIn,
  } = authResponse || {};
  const integrationReady = !!accessToken;

  if (integrationReady) {
    state = AODOCS_AUTHORIZATION_STATES.authorized;
    const authorization = `${tokenType} ${accessToken}`;
    createAPIInstance(authorization, expiresIn);
  }

  if (callback) {
    return callback({
      integrationReady,
      state,
      success: !!AODOCS_AUTHORIZATION_STATES.authorized,
      fail: !AODOCS_AUTHORIZATION_STATES.authorized,
    });
  }
  return null;
}

/**
 *  On load, called to load the auth2 library and API client library.
 */
export function handleClientLoad(callback) {
  window.gapi.load("client:auth2", callback);
}

export function initClient(callback) {
  // Initialize the gapi.client object, which app uses to make API requests.
  // Get API key and client ID from API Console.
  // 'scope' field specifies space-delimited list of access scopes.
  gapi.client
    .init({
      clientId: AODOCS_CLIENT_ID,
      scope: AODOCS_SCOPES,
    })
    .then(() => {
      const GoogleAuth = gapi.auth2.getAuthInstance();

      // Listen for sign-in state changes.
      GoogleAuth.isSignedIn.listen();

      // Handle initial sign-in state. (Determine if user is already signed in.)
      const user = GoogleAuth.currentUser.get();
      if (user) {
        handleSuccess(user, callback);
      }
    });
}

/**
 *  Sign in the user upon button click.
 */
export function handleAuthClick(callback) {
  window.gapi.auth2
    .getAuthInstance()
    .signIn()
    .then((user) => handleSuccess(user, callback));
}

/**
 *  Sign out the user upon button click.
 */
export function handleSignoutClick(event) {
  window.gapi.auth2.getAuthInstance().signOut();
}
