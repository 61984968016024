import React from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";

import { BodyUI, TextGamma } from "@happeouikit/typography";
import { ButtonPrimary } from "@happeouikit/buttons";
import messages from "./messages";
import { ASSETS_ROOT } from "../../constants";

const ErrorPage = ({ hideAction, containerStyles, imageStyles }) => {
  const intl = useIntl();
  return (
    <Container style={containerStyles}>
      <Img src={`${ASSETS_ROOT}/error.svg`} alt="" style={imageStyles} />
      <TextGamma style={{ marginBottom: "16px" }}>
        {intl.formatMessage(messages.serverError)}
      </TextGamma>
      <BodyUI>{intl.formatMessage(messages.serverErrorMsg)}</BodyUI>
      {!hideAction && (
        <ButtonRow>
          <ButtonPrimary
            text={intl.formatMessage(messages.reloadPage)}
            onClick={() => window.location.reload()}
          />
        </ButtonRow>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
`;

const Img = styled.img`
  width: 350px;
  margin: 50px auto;
`;

const ButtonRow = styled.div`
  margin-top: 30px;
`;
export default ErrorPage;
