import styled from "styled-components";
import { TinyText } from "@happeouikit/typography";

export const HeaderRow = styled.div`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    height: 20px;
    margin-right: 16px;
  }
`;
export const MetadataItem = styled.div`
  & + & {
    margin-top: 16px;
  }
`;
export const MetadataHeader = styled(TinyText)`
  margin-bottom: 4px;
`;
export const MetadataContent = styled.div`
  display: flex;
  align-items: center;
  max-height: 20px;
`;
export const MetadataAction = styled.div`
  margin-left: 8px;
  display: flex;
  align-items: center;
`;
export const MetadataTable = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
`;
export const MetadataTableColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 8px;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  :last-child {
    padding-left: 8px;
    padding-right: 0;
  }
`;
export const PersonFieldWrap = styled.div`
  display: flex;
  align-items: center;
`;
export const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const Field = styled.li`
  margin-bottom: 16px;
  :last-child {
    margin-bottom: 0;
  }
`;
