import React from "react";
import { FormattedMessage } from "react-intl";
import { Tooltip } from "@happeouikit/tooltip";
import { BodyUI } from "@happeouikit/typography";
import moment from "moment";
import {
  MetadataItem,
  MetadataTable,
  MetadataTableColumn,
  MetadataHeader,
} from "./SharedStyles";

const DocumentDates = ({ document }) => (
  <MetadataItem>
    <MetadataTable>
      <MetadataTableColumn>
        <MetadataHeader uppercase bold>
          <FormattedMessage
            id="aodocsMetadata.metadata.item.content.created.at"
            defaultMessage="Created at {date}"
            values={{
              date: (
                <span data-tip data-for={`${document.id}_created_at`}>
                  {moment(document.creationDate, "x").format("LL")}
                </span>
              ),
            }}
          />
        </MetadataHeader>
        <Tooltip
          id={`${document.id}_created_at`}
          content={moment(document.creationDate, "x").format("LLLL")}
        />
        <BodyUI>{document.author.name}</BodyUI>
      </MetadataTableColumn>
      <MetadataTableColumn>
        <MetadataHeader uppercase bold>
          <FormattedMessage
            id="aodocsMetadata.metadata.item.content.updated.at"
            defaultMessage="Updated at {date}"
            values={{
              date: (
                <span data-tip data-for={`${document.id}_updated_at`}>
                  {moment(document.modificationDate, "x").format("LL")}
                </span>
              ),
            }}
          />
        </MetadataHeader>
        <Tooltip
          id={`${document.id}_updated_at`}
          content={moment(document.modificationDate, "x").format("LLLL")}
        />
        <Tooltip
          id={`${document.id}_created_at`}
          content={moment(document.creationDate, "x").format("LLLL")}
        />
        <BodyUI>{document.updater.name}</BodyUI>
      </MetadataTableColumn>
    </MetadataTable>
  </MetadataItem>
);

export default DocumentDates;
