import { emitToParent } from "../../services/iframeCommunication";
import { ANALYTICS_EVENT } from "../../services/iframeCommunication/actions";

// Keep the name "_sendToAngular" to remind us that these are going through Angular
function _sendToAngular(eventName, properties, options) {
  emitToParent({
    [ANALYTICS_EVENT]: {
      eventName,
      properties,
      options
    }
  });
}

export function trackEvent(eventName, properties, options) {
  _sendToAngular(eventName, properties, options);
}
