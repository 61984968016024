import React, { useState } from "react";
import { useIntl } from "react-intl";
import { ContentRenderer } from "@happeouikit/content-renderer";
import { TextZeta } from "@happeouikit/typography";
import { IconExpandLess, IconExpand } from "@happeouikit/icons";
import { gray09 } from "@happeouikit/colors";
import { IconButton } from "@happeouikit/buttons";
import { SectionHeader } from "./SharedStyles";
import messages from "../../messages";

const Description = ({ richText }) => {
  const [isOpen, setIsOpen] = useState(false);
  const intl = useIntl();

  return (
    <>
      <SectionHeader>
        <TextZeta style={{ marginBottom: "8px" }}>
          {intl.formatMessage(messages.metadataSectionHeaderDescription)}
        </TextZeta>
        <IconButton
          aria-label={intl.formatMessage(messages.metadataSectionExpand)}
          icon={isOpen ? IconExpandLess : IconExpand}
          onClick={() => setIsOpen(!isOpen)}
        />
      </SectionHeader>
      {isOpen && (
        <div
          style={{
            padding: "16px",
            borderRadius: "6px",
            marginBottom: "16px",
            backgroundColor: gray09,
          }}
        >
          <ContentRenderer type="html" content={richText} />
        </div>
      )}
    </>
  );
};

export default Description;
