import React, { useEffect, useState } from "react";
import loadjs from "loadjs";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { TranslationsProvider} from "@universe/react-translations";

import { handleClientLoad, initClient } from "./services/AODocs/authentication";
import { MetadataQuery } from "./components/AODocs/MetadataQuery";

import { AuthorizationListener } from "./components/AODocs/Authorization";

import {
  AODOCS_INTEGRATION_READY,
  IDENTIFY_PARENT,
} from "./services/iframeCommunication/actions";
import {
  emitToParent,
  authorizeEvent,
  setTargetOrigin,
} from "./services/iframeCommunication";
import { ViewPicker } from "./components/AODocs/ViewPicker";

import AuthorizationWrapper from "./components/AODocs/Authorization/AuthorizationWrapper";
import { Metadata } from "./components/AODocs/Metadata";

const App = () => {
  const [jsLoaded, setJsLoaded] = useState(false);
  const [parentIdentified, setParentIdentified] = useState(false);
  const [localeData, setLocaleData] = useState({});
  const [aODocsIntegrationState, setAODocsIntegrationState] = useState(false);

  useEffect(() => {
    if (jsLoaded) return;

    const urlParams = new URLSearchParams(window.location.search);

    // Set value or as undefined. If using just null, this won't work
    const locale = urlParams.get("locale") || undefined;
    const dow = urlParams.get("dow") || undefined;
    const clockMode = urlParams.get("clockMode") || undefined;
    setLocaleData({ locale, dow, clockMode });

    loadjs(["https://apis.google.com/js/api.js"], "googleApi");
    loadjs.ready("googleApi", () => {
      handleClientLoad(() => {
        initClient((response) => {
          setAODocsIntegrationState(response.state);
          if (jsLoaded) return;
          emitToParent({ [AODOCS_INTEGRATION_READY]: true });
          setJsLoaded(true);
        });
      });
    });
  }, [jsLoaded]);

  useEffect(() => {
    if (parentIdentified) return;

    function listener(event) {
      authorizeEvent(event).then((allowed) => {
        if (!allowed) return;
        const { data } = event;
        if (data[IDENTIFY_PARENT]) {
          setTargetOrigin(event);
          setParentIdentified(true);
          window.removeEventListener("message", listener);
        }
      });
    }

    window.addEventListener("message", (event) => listener(event), false);
    return window.removeEventListener("message", listener);
  }, [parentIdentified]);

  if (!jsLoaded || !parentIdentified) return null;

  return (
    <Router>
      <TranslationsProvider
        {...localeData}
        file={"frontend-integrations.json"}
        loadBeforeRender={false}>
        <Switch>
          <Route path="/aodocs/authorize">
            <AuthorizationListener />
          </Route>
          <Route path="/aodocs/picker">
            <AuthorizationWrapper
              state={aODocsIntegrationState}
              stateChange={(response) => {
                setAODocsIntegrationState(response.state);
              }}
            >
              <ViewPicker
                setAODocsIntegrationState={setAODocsIntegrationState}
              />
            </AuthorizationWrapper>
          </Route>
          <Route
            path="/aodocs/metadataquery/:documentId"
            render={(props) => (
              <AuthorizationWrapper
                state={aODocsIntegrationState}
                type="none"
                stateChange={({ state }) => setAODocsIntegrationState(state)}
              >
                <MetadataQuery
                  match={props.match}
                  setAODocsIntegrationState={setAODocsIntegrationState}
                />
              </AuthorizationWrapper>
            )}
          />
          <Route
            path={[
              "/aodocs/metadata/:documentId",
              "/aodocs/metadata/:documentId/:type",
            ]}
            exact
            render={(props) => (
              <AuthorizationWrapper
                state={aODocsIntegrationState}
                stateChange={({ state }) => setAODocsIntegrationState(state)}
              >
                <Metadata
                  match={props.match}
                  setAODocsIntegrationState={setAODocsIntegrationState}
                />
              </AuthorizationWrapper>
            )}
          />
        </Switch>
      </TranslationsProvider>
    </Router>
  );
};

export default App;
