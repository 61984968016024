const prodConf = {
  ALLOWED_ORIGINS: [
    "https://app.happeo.com",
    "https://app.getuniverse.com",
    "https://scoop.scmp.com",
    "https://river.paf.com",
    "https://intranet.aquafil.com",
    "https://universe.gapps.fi",
    "https://universe.trimble.com",
    "https://feather.rovio.com",
    "https://sofia.evac.com",
    "https://kalevantori.kaleva.fi",
    "https://entra.enersense.com",
    "https://bintra.bygghemma.fi",
    "https://cosmos.neste.com",
    "https://jasso.kirjapaja.fi",
    "https://infohub.rovio.com",
    "https://mytruenorth.news",
    "https://lens.bbfc.co.uk",
    "https://happeo.montereycoe.org",
    "https://yleplus.yle.fi",
    "https://ollie.givaudan.com",
    "https://atbconnect.atb.com",
    "https://space.visma.com",
    "https://loop.gant.com",
    "https://agbiomeconnect.com",
    "https://eptmatrix.com",
    "https://intranet.mhpteamsi.com",
    "https://staffhub.uwcsea.edu.sg",
    "https://central.swmc.com",
    "https://theloop.inoc.com",
    "https://cruxconnect.me",
    "https://netanet.mx",
    "https://hub.younginvincibles.org",
    "https://myaurora.tech",
    "https://workspot.happeo.com",
    "https://devtest.happeo.com",
    "https://hq.planful.com",
    "https://mykairoi.com",
    "https://happeo.nordeus.com",
    "https://test-atbconnect.atb.com",
    "https://happypilot.solvay.com",
    "https://pipeline.beyondretail.co.uk",
    "https://intra.teamolivia.se",
    "https://lifeonsmars.smartrecruiters.com",
    "https://minthub.net",
    "https://intranet.ice.it",
    "https://happeo.sambanovasystems.com",
    "https://wearewtv.wtvglobal.com",
    "https://intranet.phobio.com",
    "https://portti.hamina.fi",
    "https://happeo.nextnovate.com",
    "https://engage.ryff.com",
    "https://kadashboard.com",
    "https://happeo.merig.com",
    "https://happeo.formitable.com",
    "https://theclubhouse.huntclub.com",
    "https://my.1337.life",
  ],
};
const devConf = {
  ALLOWED_ORIGINS: [
    "https://staging.unvrs.io",
    "https://app.happeo.com",
    "http://localhost:3003",
    "http://localhost:8000",
  ],
};

const isProd = window.location.host.includes("happeo.com");
const configuration = isProd ? prodConf : devConf;

export const ALLOWED_ORIGINS = configuration.ALLOWED_ORIGINS;
export const ASSETS_ROOT = "https://cdn.happeo.com/misc";
