import { useEffect, useState } from "react";
import { makeCancelable } from "@universe/frontend-utils";

import { AODOCS_METADATA_QUERY_RESULT } from "../../../services/iframeCommunication/actions";
import { emitToParent } from "../../../services/iframeCommunication";
import {
  getDocumentByDriveId,
  isAuthorizationRequired,
} from "../../../services/AODocs/api";
import { AODOCS_AUTHORIZATION_STATES } from "../../../services/AODocs/constants";

const MetadataQuery = ({ match, setAODocsIntegrationState }) => {
  const [documentId] = useState(match?.params?.documentId);
  useEffect(() => {
    if (!documentId) return;

    const cancelableGetDocument = makeCancelable(
      getDocumentByDriveId(documentId),
    );

    cancelableGetDocument.promise
      .then(({ data }) => {
        emitToParent({ [AODOCS_METADATA_QUERY_RESULT]: { data, documentId } });
      })
      .catch((error) => {
        if (error.isCanceled) {
          // Do nothing
        }
        if (isAuthorizationRequired(error.response)) {
          setAODocsIntegrationState(AODOCS_AUTHORIZATION_STATES.unauthorized);
        } else {
          emitToParent({ [AODOCS_METADATA_QUERY_RESULT]: { documentId } });
        }
      });

    return () => {
      cancelableGetDocument.cancel();
    };
  }, [documentId, setAODocsIntegrationState]);

  return null;
};

export default MetadataQuery;
