import { ALLOWED_ORIGINS } from "./constants";
import { AODOCS_INTEGRATION_READY } from "./actions";

let messageOrigin;

function getTargetOrigin() {
  if (!messageOrigin) {
    console.error("Missing message origin");
  }
  return messageOrigin;
}

export function setTargetOrigin({ origin }) {
  if (!ALLOWED_ORIGINS.includes(origin)) {
    return false;
  }
  messageOrigin = origin;
}

export async function authorizeEvent(event = {}) {
  const { origin } = event;

  return ALLOWED_ORIGINS.includes(origin);
}

// Add return values to allow testing
export function emitToParent(eventData) {
  try {
    if (!eventData) {
      return false;
    }

    let targetOrigin = "*";
    if (!eventData[AODOCS_INTEGRATION_READY]) {
      targetOrigin = getTargetOrigin();
    }
    window.parent.postMessage(eventData, targetOrigin);
    return true;
  } catch (error) {
    // Lets just stay quiet here, no need to say that something went wrong.
    // if (process.env.NODE_ENV === "development") {
    console.error("EMIT ERROR", error);
    // }
  }
}
