import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { makeCancelable } from "@universe/frontend-utils";
import { Tooltip } from "@happeouikit/tooltip";
import { BodyUI } from "@happeouikit/typography";
import { LinkExternal, Badge } from "@happeouikit/form-elements";
import { trackEvent, AODOCS_OPEN_DOCUMENT } from "../../../AppAnalytics";

import {
  MetadataItem,
  MetadataHeader,
  MetadataContent,
  MetadataAction,
} from "./SharedStyles";
import messages from "../../messages";
import { getDocumentWorkflowTransitions } from "../../../../services/AODocs/api";

const DocumentWorkflowState = ({ document }) => {
  const [workflowTransitions, setWorkflowTransitions] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    if (!document || !document.id) return;
    const cancelableGetDocumentWorkflowTransitions = makeCancelable(
      getDocumentWorkflowTransitions(document.id),
    );
    cancelableGetDocumentWorkflowTransitions.promise
      .then(({ data }) => {
        setWorkflowTransitions(data.items);
      })
      .catch(() => {});

    // eslint-disable-next-line consistent-return
    return () => {
      cancelableGetDocumentWorkflowTransitions.cancel();
    };
  }, [document]);

  return (
    <MetadataItem>
      <MetadataHeader uppercase bold>
        {intl.formatMessage(messages.metadataItemHeaderWorkflowState)}
      </MetadataHeader>
      <MetadataContent>
        <BodyUI>{document.state_i18n}</BodyUI>
        {workflowTransitions && (
          <MetadataAction>
            <LinkExternal
              href={document.documentUrl}
              onClick={() => {
                trackEvent(AODOCS_OPEN_DOCUMENT);
              }}
              data-tip
              data-for={`${document.id}_state`}
            >
              <Badge
                text={intl.formatMessage(messages.metadataActionsAvailable)}
              />
              <Tooltip
                id={`${document.id}_state`}
                content={intl.formatMessage(
                  messages.metadataButtonOpenDocument,
                )}
              />
            </LinkExternal>
          </MetadataAction>
        )}
      </MetadataContent>
    </MetadataItem>
  );
};

export default DocumentWorkflowState;
