import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { BodyUI, TextGamma } from "@happeouikit/typography";
import messages from "./messages";
import { ASSETS_ROOT } from "../../constants";
import { useIntl } from "react-intl";

const NoResults = ({ subtitle, containerStyles = {}, imageStyles = {} }) => {
  const intl = useIntl();
  return (
    <Container style={containerStyles}>
      <Img src={`${ASSETS_ROOT}/no-results.svg`} alt="" style={imageStyles} />
      <TextGamma>{intl.formatMessage(messages.noResultsTitle)}</TextGamma>
      {subtitle && <BodyUI>{subtitle}</BodyUI>}
    </Container>
  );
};

NoResults.propTypes = {
  subtitle: PropTypes.node.isRequired,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
`;

const Img = styled.img`
  width: 350px;
  margin: 50px auto;
`;

export default NoResults;
