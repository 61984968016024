export const AODOCS_INTEGRATION_READY = "AODOCS_INTEGRATION_READY";
export const IDENTIFY_PARENT = "IDENTIFY_PARENT";

export const RESIZE_EVENT = "RESIZE_EVENT";
export const RECEIVED_RESULTS = "RECEIVED_RESULTS";

export const ANALYTICS_EVENT = "ANALYTICS_EVENT";
export const ENABLE_INTEGRATION = "ENABLE_INTEGRATION";
export const AUTHORIZE_EVENT = "AUTHORIZE_EVENT";
export const AODOCS_AUTHORIZATION_STATUS = "AODOCS_AUTHORIZATION_STATUS";
export const AODOCS_UNAUTHORIZED = "AODOCS_UNAUTHORIZED";
export const AODOCS_AUTHORIZE_EVENT_DONE = "AODOCS_AUTHORIZE_EVENT_DONE";
export const AODOCS_METADATA_QUERY_RESULT = "AODOCS_METADATA_QUERY_RESULT";
export const AODOCS_PICKER_EVENT = "AODOCS_PICKER_EVENT";
