import { defineMessages } from "react-intl.macro";

export default defineMessages({
  modalFilterPlaceholder: {
    id: "aodocsModal.input.filter.placeholder",
    defaultMessage: "Search",
  },
  cancelButton: {
    id: "aodocsPickerView.button.cancel",
    defaultMessage: "Cancel",
  },
  selectButton: {
    id: "aodocsPickerView.button.select",
    defaultMessage: "Select",
  },
  backButton: {
    id: "aodocsPickerView.button.back",
    defaultMessage: "Back",
  },
  openLibraryButton: {
    id: "aodocsPickerView.button.openLibrary",
    defaultMessage: "Open library",
  },
  headerLibrary: {
    id: "aodocsPickerView.header.library",
    defaultMessage: "Library",
  },
  headerView: {
    id: "aodocsPickerView.header.view",
    defaultMessage: "View",
  },
  metadataButtonTooltip: {
    id: "aodocsMetadata.button.tooltip",
    defaultMessage: "AODocs metadata",
  },
  metadataButtonShowDetails: {
    id: "aodocsMetadata.metadata.button.showDetails",
    defaultMessage: "Show document details",
  },
  metadataButtonOpenDocument: {
    id: "aodocsMetadata.metadata.button.openDocument",
    defaultMessage: "Open document",
  },
  metadataItemHeaderWorkflowState: {
    id: "aodocsMetadata.metadata.item.header.workflowstate",
    defaultMessage: "Workflow state",
  },
  metadataItemHeaderDocument: {
    id: "aodocsMetadata.metadata.item.header.document",
    defaultMessage: "Document",
  },
  metadataItemHeaderLibrary: {
    id: "aodocsMetadata.metadata.item.header.library",
    defaultMessage: "Library",
  },
  metadataSectionExpand: {
    id: "aodocsMetadata.metadata.section.button.expand",
    defaultMessage: "Expand section",
  },
  metadataSectionHeaderDescription: {
    id: "aodocsMetadata.metadata.section.header.description",
    defaultMessage: "Description",
  },
  metadataActionsAvailable: {
    id: "aodocsMetadata.metadata.item.actions.available",
    defaultMessage: "Actions available",
  },
  metadataSectionItemOpenMap: {
    id: "aodocsMetadata.metadata.section.openmap",
    defaultMessage: "Open map",
  },
  unauthorizedTitle: {
    id: "aodocs.unauthorized.title",
    defaultMessage: "AODocs seems to be unauthorized",
  },
  unauthorizedSubtitle: {
    id: "aodocs.unauthorized.subtitle",
    defaultMessage: "Please reauthorize AODocs in order to use it again.",
  },
});
